<template>
  <v-container class="dv-container">
    <h1 class="text-title">Le projet Divergent : Diversité-Genre-Enseignement</h1>
    <div>
      <p>DIVERGENT est un projet qui propose deux types d'actions pour sensibiliser les acteurs et actrices de la HES-SO à poser des petits pas dans la direction de l'intégration des défis liés au genre et à la diversité dans leurs missions. Le premier outil, Un pas en avant, est mobilisé pour sensibiliser tant les étudiant-e-s, le personnel d'enseignement et de recherche que le personnel administratif et technique aux problématiques de la discrimination. Le deuxième outil, le Gender and Diversity Index (GDI), destiné essentiellement aux enseignant-e-s, vise à leur permettre d'auto-évaluer leurs pratiques d'enseignement à l'aune du genre et de la diversité.</p>
    </div>
    <v-row align="center" justify-md="flex-end" justify-sm="center">
      <v-spacer class="d-none d-md-block"></v-spacer>
      <v-col md="6" class="no-gutters py-0">
        <img src="@/assets/HES-SO_logo.png" width="100" class="d-inline-block" align="center"/>
        <img src="@/assets/HETSL_logo.png" width="100" class="d-inline-block" align="center"/>
        <img src="@/assets/HEG_logo.png" width="250" class="d-inline-block" align="center"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "About",
    data() {
      return {
      }
    }
  }
</script>

<style scoped>
  p {
    padding-top: 15px;
  }
  h1 {
    font-size: 45px;
  }
</style>